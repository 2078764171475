<template>
 <div class="mg">
    <img class="gongyi" src="@/assets/images/charity.jpg" alt="" />

    <div class="control">
      <div class="text">
        体育公益扶志青春，为最美梦想助力。派燃烧“爱益启”助力学子体育梦，派燃烧秉承着，为中国体育事业助力的理念，长久以来，助力了万千学子的体育梦。派燃烧希望能尽自己的一份微薄之力，让体育生对体育更加充满信心和勇气，让他们感受到我们对他们深深的爱与希望，也感受到无处不在的体育精神。
      </div>
      <div>
        <img src="@/assets/images/public-go.jpg" alt="" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "PublicWelfare",
};
</script>

<style>
.gongyi {
  width: 100%;
}
.control {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  width: 937px; 
  
  font-size: 25px;
  line-height: 40px;
  text-indent: 4em;
  padding: 40px 0;
  /* font-weight: 700; */
  color: rgb(21, 20, 20);
}
</style>